import React, { useState } from "react";
import { Route } from "react-router-dom";
import { Box, Modal } from "@material-ui/core";
import useLiff from "../hooks/useAuth";
import { encode } from "../page/helpper";
import {
  checkProfile,
  myProfile,
  tracker,
  useAPIQuery,
} from "../hooks/queries";
import { API } from "../hooks/api";
import muiStyles from "../page/muiStyle";
import {
  getBranchID,
  getRequiredLoginWithVespaState,
  resetRequiredLoginWithVespaState,
  setRequiredLoginWithVespaState,
} from "../hooks/user";
import { useMutation } from "react-query";

export const RouteWithPermission = ({ path, component: Component }) => {
  const classes = muiStyles();
  const { getProfile, getAccessToken } = useLiff();
  const [isOpenModal, setIsOpenModal] = useState();

  const encodedData = encode({
    accesstoken: getAccessToken,
    lineuserid: getProfile?.userId,
  });

  const mutation = useMutation({
    mutationFn: tracker,
  });

  const { data: checkProfileData } = useAPIQuery("checkProfile", () =>
    checkProfile(encodedData),
  );

  const { status } = useAPIQuery("myProfile", () => myProfile(encodedData), {
    onSuccess: (res) => {
      const reqiredLoginWithVespa =
        res?.profile?.vespistiid === "" && path !== "/vespaid_callback";
      setRequiredLoginWithVespaState(reqiredLoginWithVespa);
    },
  });

  if (
    checkProfileData?.detail === "profile_not_existing" &&
    path !== "/vespaid_callback"
  ) {
    window.location.href = API.LOGIN_WITH_VESPA_ID;
    return;
  }

  const reqiredLoginWithVespa =
    isOpenModal ?? getRequiredLoginWithVespaState() ?? false;

  return (
    <Route
      path={path}
      exact={true}
      component={() => (
        <>
          <Component />
          {status === "success" && (
            <Modal
              disablePortal
              disableEnforceFocus
              disableAutoFocus
              open={reqiredLoginWithVespa}
              sx={{
                display: "flex",
                p: 1,
                alignItems: "center",
                backdropFilter: "blur(5px)",
                backgroundColor: "red",
              }}
              style={{
                backdropFilter: "blur(5px)",
                backgroundColor: "rgba(33, 191, 149, 0.7)",
              }}
            >
              <Box
                sx={{
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  position: "relative",
                  bgcolor: "bottom",
                  textAlign: "-webkit-center",
                  p: 4,
                }}
              >
                <div className={classes.vespistiModalContnet}>
                  เพื่อประสบการณ์ที่ดีกว่า <br />
                  ท่านสามารถสมัคร <br />
                  Vespisti ID <br />
                  เพื่อรับสิทธิพิเศษจากเรา
                </div>
                <div className={classes.vespistiModalRegisterButtonContainer}>
                  <a
                    className={classes.vespistiModalRegisterButton}
                    onClick={() => (window.location = API.LOGIN_WITH_VESPA_ID)}
                  >
                    สมัครเลย
                  </a>
                  <a
                    className={classes.vespistiModalRegisterButton}
                    style={{ background: "white", color: "black" }}
                    onClick={() => {
                      const encodedData = encode({
                        accesstoken: getAccessToken,
                        code: "VespistiRegisterClose",
                        oaid: getBranchID(),
                      });
                      setIsOpenModal(false);
                      resetRequiredLoginWithVespaState();

                      mutation.mutate(encodedData);
                    }}
                  >
                    ปิด
                  </a>
                </div>
              </Box>
            </Modal>
          )}
        </>
      )}
    />
  );
};
