import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Privilege from "../images/menu/Privilege.png";
import PrivilegeActive from "../images/menu/Privilege-active.png";
import AccountInfo from "../images/menu/Account-Info.png";
import AccountInfoActive from "../images/menu/Account-Info-active.png";
import MyCoupon from "../images/menu/My-coupon.png";
import MyCouponActive from "../images/menu/My-coupon-active.png";
import "../App.css";
import { useHistory } from "react-router-dom";
import {getBranchID} from "../hooks/user";

const useStyles = makeStyles(() => ({
  footer: {
    width: "100%",
    textAlign: "center",
    display: "flex",
    gap: "1px",
    overflow: "hidden",
    boxSizing: "border-box",
    position: "fixed",
    padding: "5px",
    bottom: "0",
    left: "0",
  },
  footerImage: {
    flex: "1 1 calc((100% - 2px) / 3)",
    maxWidth: "calc((100% - 2px) / 3)",
    height: "auto",
    display: "block",
  },
}));

const Footer = ({ imageIndex }) => {
  const classes = useStyles();
  const history = useHistory();
  const branchId = getBranchID();

  const images = {
    1: AccountInfoActive,
    2: MyCouponActive,
    3: PrivilegeActive,
  };

  const imgSources = [
    imageIndex === 1 ? images[1] : AccountInfo,
    imageIndex === 2 ? images[2] : MyCoupon,
    imageIndex === 3 ? images[3] : Privilege,
  ];

  return (
    <div className={clsx(classes.footer)}>
      <img
        className={classes.footerImage}
        src={imgSources[0]}
        alt="Account Info"
        onClick={() => history.push(`/my-profile?branchId=${branchId}`)}
      />
      <img
        className={classes.footerImage}
        src={imgSources[1]}
        alt="My Coupon"
        onClick={() => history.push(`/my-new-coupon?branchId=${branchId}`)}
      />
      <img
        className={classes.footerImage}
        src={imgSources[2]}
        alt="Privilege"
        onClick={() => history.push(`/privileges?branchId=${branchId}`)}
      />
    </div>
  );
};

export default Footer;
