import React, { useState, useEffect } from "react";
import HeaderBgImg from "../images/header__background.png";
import "../App.css";
import { makeStyles } from "@material-ui/core";
import Footer from "../components/Footer";
import muiStyles from "./muiStyle";
import useLiff from "../hooks/useAuth";
import axios from "axios";
import { API, isDealer } from "../hooks/api";
import { availableMonths, encode } from "./helpper";
import clsx from "clsx";
import Loading from "../components/Loading";
import {
  getModels,
  myProfile,
  queryClient,
  useAPIQuery,
} from "../hooks/queries";
import VespistiLogo from "../images/vespistiLogo.svg";
import AppBarNone from "../components/AppBarNone";

const useStyles = makeStyles({
  container: {
    backgroundImage: `url(${HeaderBgImg})`,
    backgroundPosition: "0% 0%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% auto",
    minHeight: "100vh",
  },
});

const VaspistiIDVerified = () => {
  const classes = muiStyles();
  return (
    <a href={API.VESPISTI_ACCOUNT} style={{ display: "grid" }}>
      <img src={VespistiLogo} alt="" />
      <label
        className={classes.formLabel}
        style={{ fontWeight: "bold", color: "black" }}
      >
        เข้าสู่ Vespisti ID
      </label>
    </a>
  );
};

const RequiredVaspistiID = () => {
  const classes = muiStyles();
  return (
    <>
      <label className={classes.formLabel} style={{ marginTop: "15px" }}>
        คุณยังไม่มี Vespisti ID
      </label>
      <label className={classes.formLabel}>
        <a
          style={{ fontWeight: "bold", color: "black" }}
          href={API.LOGIN_WITH_VESPA_ID}
        >
          สมัคร Vespiti ID
        </a>
      </label>
    </>
  );
};

const ProfileHeader = ({ reqiredLoginWithVespa }) =>
  reqiredLoginWithVespa ? <RequiredVaspistiID /> : <VaspistiIDVerified />;

const MyProfile = () => {
  const classes = muiStyles();
  const [language, setLanguage] = useState("th");
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const { getProfile, getAccessToken } = useLiff();
  const token = getAccessToken;
  const profile = getProfile;

  const onClickTrack = async (token) => {
    const queryParams = new URLSearchParams(window.location.search);
    const tracking = queryParams.get("track");

    if (tracking) {
      const param = {
        accesstoken: token,
        code: "MainAccountProfile",
      };
      const encodedData = encode(param);
      const response = await axios.post(API.TRACKER, encodedData);
      console.log("track", response);
    }
  };
  onClickTrack(token);

  const param = {
    accesstoken: token,
    lineuserid: profile.userId,
  };
  const encodedData = encode(param);
  const models = useAPIQuery("models", () => getModels(encodedData));
  const response = useAPIQuery("myProfile", () => myProfile(encodedData));
  const modelList = models.data?.data ?? [];

  const profileData = response?.data?.profile;
  const data = {
    ...profileData,
    date: parseInt((profileData?.bhd || "").split("-")[2]),
    month: parseInt((profileData?.bhd || "").split("-")[1]),
    year: parseInt((profileData?.bhd || "").split("-")[0]),
  };

  const [formData, setFormData] = useState({});
  const [formBlur, setFormBlur] = useState({});

  useEffect(() => {
    setFormData({
      ...data,
      tankNumber: data.chassisnumber,
    });
  }, [showEdit]);

  const onChangeInput = (e) => {
    let { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormBlur({
      ...formBlur,
      [name]: true,
    });
  };

  const onBlur = (e) => {
    setFormBlur({
      ...formBlur,
      [e.target.name]: true,
    });
  };

  const onChangeStatus = (e, value) => {
    setFormData({
      ...formData,
      vespaowner: value,
      enginenumber: value === "true" ? formData.enginenumber : "",
    });
  };

  const onSubmit = () => {
    setFormBlur({
      email: true,
      mobile: true,
      postal: true,
      vespamodel: true,
      enginenumber: true,
      tankNumber: true,
    });

    if (
      formData.postal &&
      formData.postal.length === 5 &&
      !isNaN(formData.postal) &&
      formData.mobile &&
      formData.mobile.length === 10 &&
      !isNaN(formData.mobile) &&
      formData.vespamodel
    ) {
      var check = true;
      if (formData.vespaowner === "true") {
        if (
          !formData.enginenumber ||
          formData.enginenumber.length < 12 ||
          !/^[a-zA-Z0-9]+$/.test(formData.enginenumber) ||
          !formData.tankNumber ||
          formData.tankNumber.length < 17 ||
          !/^[A-Za-z]{2}[0-9]/.test(formData.tankNumber)
        ) {
          check = false;
        }
      }

      if (check) {
        const param = {
          accesstoken: token,
          lineuserid: profile.userId,
          firstname: data.firstname,
          lastname: data.lastname,
          mobile: formData.mobile,
          email: formData.email,
          gender: data.gender,
          bhd: data.bhd,
          postal: formData.postal,
          vespaowner: formData.vespaowner === "true" ? 1 : 0,
          vespamodel: formData.vespamodel,
          enginenumber: formData.enginenumber,
          chassisnumber: formData.tankNumber,
          lat: "",
          long: "",
          lang: language,
        };
        const encodedData = encode(param);
        setLoading(true);
        (async () => {
          const response = await axios.post(API.UPDATE_USER, encodedData);

          if (response?.data?.result === "complete") {
            setLoading(false);
            await queryClient.invalidateQueries("myProfile");
          } else {
            setLoading(false);
            alert(response?.data?.detail || "แก้ไขข้อมูลไม่สำเร็จ");
          }
          (async () => {
            setShowEdit(false);
          })();
        })();
      }
    }
  };

  const reqiredLoginWithVespa = profileData?.vespistiid === "";

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {loading && <Loading />}

        <div className="header_profile">
          <div className={classes.avatarFrame}>
            <img className="avatar__image" src={profile?.pictureUrl} alt="" />
          </div>
          {response.status === "success" && (
            <ProfileHeader reqiredLoginWithVespa={reqiredLoginWithVespa} />
          )}
          <div className={classes.language}>
            <a
              className={
                language === "th"
                  ? classes.languageAnchorActive
                  : classes.languageAnchor
              }
              onClick={(e) => setLanguage("th")}
            >
              TH
            </a>
            |
            <a
              className={
                language === "en"
                  ? classes.languageAnchorActive
                  : classes.languageAnchor
              }
              onClick={(e) => setLanguage("en")}
            >
              EN
            </a>
          </div>
        </div>

        <form className={classes.form2}>
          <div className={classes.formItem}>
            <div className={classes.formItem2}>
              <label className={classes.formLabel}>
                {language === "en" ? "First name" : "ชื่อ"}
              </label>
            </div>
            <div className={classes.formItem2}>
              <label className={classes.formLabel}>{data?.firstname}</label>
            </div>
          </div>

          <div className={classes.formItem}>
            <div className={classes.formItem2}>
              <label className={classes.formLabel}>
                {language === "en" ? "Last name" : "นามสกุล"}
              </label>
            </div>
            <div className={classes.formItem2}>
              <label className={classes.formLabel}>{data?.lastname}</label>
            </div>
          </div>

          <div className={classes.formItem}>
            <div className={classes.formItem2}>
              <label className={classes.formLabel}>
                {language === "en" ? "Gender" : "เพศ"}
              </label>
            </div>
            <div className={classes.formItem2}>
              <label className={classes.formLabel}>
                {language === "en"
                  ? data.gender === "male"
                    ? "Male"
                    : "Female"
                  : data.gender === "male"
                    ? "ชาย"
                    : "หญิง"}
              </label>
            </div>
          </div>

          <div className={classes.formItem}>
            <div className={classes.formItem2}>
              <label className={classes.formLabel}>
                {language === "en" ? "Birth date" : "วันเกิด"}
              </label>
            </div>
            <div className={classes.formItem2}>
              {data?.bhd && (
                <label className={classes.formLabel}>
                  {language === "en"
                    ? `${data?.date} ${availableMonths[data?.month - 1].en} ${data?.year}`
                    : `${data?.date} ${availableMonths[data?.month - 1].th} ${data?.year + 543}`}
                </label>
              )}
            </div>
          </div>

          <div
            className={classes.formItem}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className={classes.formItem2}>
              <label className={classes.formLabel}>
                {language === "en" ? "Mobile" : "เบอร์โทรศัพท์"}
              </label>
            </div>
            <div className={classes.formItem3}>
              {showEdit ? (
                <>
                  <div>
                    <input
                      className={classes.formFieldInput}
                      placeholder={
                        language === "en"
                          ? "5 digits number"
                          : "หมายเลข 10 หลัก"
                      }
                      type="text"
                      name="mobile"
                      onChange={onChangeInput}
                      onBlur={onBlur}
                      maxLength={10}
                      value={formData.mobile}
                    />
                  </div>
                  {!formData.mobile && formBlur.mobile && (
                    <div className={classes.formFieldError}>
                      {language === "en"
                        ? "This field is required."
                        : "กรุณากรอกข้อมูล"}
                    </div>
                  )}
                  {formData.mobile && (
                    <>
                      {isNaN(formData.mobile) ? (
                        <div className={classes.formFieldError}>
                          {language === "en"
                            ? "Fill in numbers only."
                            : "กรอกเป็นตัวเลขเท่านั้น"}
                        </div>
                      ) : formData.mobile.length < 10 ? (
                        <div className={classes.formFieldError}>
                          {language === "en"
                            ? "10 digit numbers."
                            : "กรอกตัวเลข 10 หลัก"}
                        </div>
                      ) : null}
                    </>
                  )}
                </>
              ) : (
                <label className={classes.formLabel}>{data?.mobile}</label>
              )}
            </div>
          </div>

          <div
            className={classes.formItem}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className={classes.formItem2}>
              <label className={classes.formLabel}>
                {language === "en" ? "Contact number" : "เบอร์ติดต่อ"}
              </label>
            </div>
            <div className={classes.formItem3}>
              <label className={classes.formLabel}>
                {Boolean(data?.vespistiidmobile) ? data?.vespistiidmobile : "-"}
              </label>
            </div>
          </div>

          <div
            className={classes.formItem}
            style={{ display: "flex", alignItems: "baseline" }}
          >
            <div className={classes.formItem2}>
              <label className={classes.formLabel}>
                {language === "en" ? "Email" : "อีเมล"}
              </label>
            </div>
            <div className={classes.formItem3}>
              {showEdit ? (
                <div>
                  <input
                    className={classes.formFieldInput}
                    placeholder={
                      language === "en" ? "Enter your email" : "กรอกอีเมลของคุณ"
                    }
                    type="email"
                    name="email"
                    onChange={onChangeInput}
                    onBlur={onBlur}
                    value={formData.email}
                  />
                </div>
              ) : (
                <label
                  className={classes.formLabel}
                  style={{ overflowWrap: "anywhere", wordBreak: "break-word" }}
                >
                  {Boolean(data?.email) ? data?.email : "-"}
                </label>
              )}
            </div>
          </div>

          <div
            className={classes.formItem}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className={classes.formItem2}>
              <label className={classes.formLabel}>
                {language === "en" ? "Postcode" : "รหัสไปรษณีย์"}
              </label>
            </div>
            <div className={classes.formItem3}>
              {showEdit ? (
                <>
                  <div>
                    <input
                      className={classes.formFieldInput}
                      placeholder={
                        language === "en" ? "5 digits number" : "หมายเลข 5 หลัก"
                      }
                      type="text"
                      name="postal"
                      onChange={onChangeInput}
                      onBlur={onBlur}
                      maxLength={5}
                      value={formData.postal}
                    />
                  </div>
                  {!formData.postal && formBlur.postal && (
                    <div className={classes.formFieldError}>
                      {language === "en"
                        ? "This field is required."
                        : "กรุณากรอกข้อมูล"}
                    </div>
                  )}
                  {formData.postal && (
                    <>
                      {isNaN(formData.postal) ? (
                        <div className={classes.formFieldError}>
                          {language === "en"
                            ? "Fill in numbers only."
                            : "กรอกเป็นตัวเลขเท่านั้น"}
                        </div>
                      ) : formData.postal.length < 5 ? (
                        <div className={classes.formFieldError}>
                          {language === "en"
                            ? "5 digit numbers."
                            : "กรอกตัวเลข 5 หลัก"}
                        </div>
                      ) : null}
                    </>
                  )}
                </>
              ) : (
                <label className={classes.formLabel}>{data?.postal}</label>
              )}
            </div>
          </div>

          <div
            className={classes.formItem}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className={classes.formItem2}>
              <label className={classes.formLabel}>
                {language === "en" ? "Have a Vespa?" : "มี Vespa หรือไม่"}
              </label>
            </div>
            <div className={classes.formItem3}>
              {showEdit ? (
                <>
                  <label
                    className={classes.formFieldWrap}
                    style={{ marginLeft: 0 }}
                  >
                    <input
                      className={classes.formFieldRadio}
                      type="radio"
                      name="vespaowner"
                      value={formData?.vespaowner === "true"}
                      onChange={(e) => onChangeStatus(e, "true")}
                    />
                    <div
                      className={
                        formData?.vespaowner === "true"
                          ? clsx(classes.formRadio, classes.formRadioBgCheck)
                          : clsx(classes.formRadio, classes.formRadioBg)
                      }
                    ></div>
                    <span className={classes.formFieldTitle}>
                      {language === "en" ? "Yes" : "มี"}
                    </span>
                  </label>
                  <label className={classes.formFieldWrap}>
                    <input
                      className={classes.formFieldRadio}
                      type="radio"
                      name="vespaowner"
                      value={formData?.vespaowner === "false"}
                      onChange={(e) => onChangeStatus(e, "false")}
                    />
                    <div
                      className={
                        formData?.vespaowner === "false"
                          ? clsx(classes.formRadio, classes.formRadioBgCheck)
                          : clsx(classes.formRadio, classes.formRadioBg)
                      }
                    ></div>
                    <span className={classes.formFieldTitle}>
                      {language === "en" ? "No" : "ไม่มี"}
                    </span>
                  </label>
                </>
              ) : (
                <label className={classes.formLabel}>
                  {language === "en"
                    ? data.vespaowner === "true"
                      ? "Yes"
                      : "No"
                    : data.vespaowner === "true"
                      ? "มี"
                      : "ไม่มี"}
                </label>
              )}
            </div>
          </div>

          <div
            className={classes.formItem}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className={classes.formItem2}>
              <label className={classes.formLabel}>
                {language === "en" ? "Have / interested" : "มีรุ่น / สนใจรุ่น"}
              </label>
            </div>
            <div className={classes.formItem3}>
              {showEdit ? (
                <>
                  <div>
                    <select
                      className={
                        formData.vespamodel
                          ? classes.formFieldInput
                          : clsx(
                              classes.formFieldInput,
                              classes.formSelectEmpty,
                            )
                      }
                      name="vespamodel"
                      value={formData.vespamodel}
                      onChange={onChangeInput}
                      onBlur={onBlur}
                    >
                      <option value="">เลือกรุ่น</option>
                      {modelList.map((m) => (
                        <option value={m.titleth} key={m.itemid}>
                          {m.titleth}
                        </option>
                      ))}
                    </select>
                  </div>
                  {!formData.vespamodel && formBlur.vespamodel && (
                    <div className={classes.formFieldError}>
                      {language === "en"
                        ? "This field is required."
                        : "กรุณากรอกข้อมูล"}
                    </div>
                  )}
                </>
              ) : (
                <label className={classes.formLabel}>{data?.vespamodel}</label>
              )}
            </div>
          </div>

          {((data.vespaowner === "true" && !showEdit) ||
            (showEdit && formData.vespaowner === "true")) && (
            <>
              <div
                className={classes.formItem}
                style={{ display: "flex", alignItems: "center" }}
              >
                <div className={classes.formItem2}>
                  <label className={classes.formLabel}>
                    {language === "en" ? "Engine number" : "เลขเครื่องยนต์"}
                  </label>
                </div>
                <div className={classes.formItem3}>
                  {showEdit ? (
                    <>
                      <div>
                        <input
                          className={classes.formFieldInput}
                          placeholder={
                            language === "en"
                              ? "Engine number"
                              : "เลขเครื่องยนต์"
                          }
                          type="text"
                          name="enginenumber"
                          onChange={onChangeInput}
                          onBlur={onBlur}
                          maxLength={12}
                          value={formData.enginenumber}
                        />
                      </div>
                      {!formData.enginenumber && formBlur.enginenumber && (
                        <div className={classes.formFieldError}>
                          {language === "en"
                            ? "This field is required."
                            : "กรุณากรอกข้อมูล"}
                        </div>
                      )}
                      {formData.enginenumber && (
                        <>
                          {!/^[a-zA-Z0-9]+$/.test(formData.enginenumber) ? (
                            <div className={classes.formFieldError}>
                              {language === "en"
                                ? "Invalid Engine number."
                                : "เลขเครื่องยนต์ไม่ถูกต้อง"}
                            </div>
                          ) : formData.enginenumber.length < 12 ? (
                            <div className={classes.formFieldError}>
                              {language === "en"
                                ? "12 digit."
                                : "ความยาว 12 หลัก"}
                            </div>
                          ) : null}
                        </>
                      )}
                    </>
                  ) : (
                    <label className={classes.formLabel}>
                      {data?.enginenumber}
                    </label>
                  )}
                </div>
              </div>
              <div
                className={classes.formItem}
                style={{ display: "flex", alignItems: "center" }}
              >
                <div className={classes.formItem2}>
                  <label className={classes.formLabel}>
                    {language === "en" ? "Tank number" : "เลขถัง"}
                  </label>
                </div>
                <div className={classes.formItem3}>
                  {showEdit ? (
                    <>
                      <div>
                        <input
                          className={classes.formFieldInput}
                          placeholder={
                            language === "en" ? "Tank number" : "เลขถัง"
                          }
                          type="text"
                          name="tankNumber"
                          onChange={onChangeInput}
                          onBlur={onBlur}
                          maxLength={17}
                          value={formData.tankNumber}
                        />
                      </div>
                      {!formData.tankNumber && formBlur.tankNumber && (
                        <div className={classes.formFieldError}>
                          {language === "en"
                            ? "This field is required."
                            : "กรุณากรอกข้อมูล"}
                        </div>
                      )}
                      {formData.tankNumber && (
                        <>
                          {!/^[A-Za-z]{2}[0-9]/.test(formData.tankNumber) ? (
                            <div className={classes.formFieldError}>
                              {language === "en"
                                ? "Input must start with two letters followed by one number."
                                : "ข้อมูลที่ป้อนต้องเริ่มต้นด้วยตัวอักษรสองตัวและตามด้วยตัวเลขหนึ่งตัว"}
                            </div>
                          ) : (
                            <>
                              {!/^[a-zA-Z0-9]+$/.test(formData.tankNumber) ? (
                                <div className={classes.formFieldError}>
                                  {language === "en"
                                    ? "Invalid Engine number."
                                    : "เลขเครื่องยนต์ไม่ถูกต้อง"}
                                </div>
                              ) : formData.tankNumber.length < 17 ? (
                                <div className={classes.formFieldError}>
                                  {language === "en"
                                    ? "17 digit."
                                    : "ความยาว 17 หลัก"}
                                </div>
                              ) : null}
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <label className={classes.formLabel}>
                      {data?.chassisnumber}
                    </label>
                  )}
                </div>
              </div>
            </>
          )}

          <div className={classes.editor}>
            {showEdit ? (
              <a className={classes.editorRedeem} onClick={onSubmit}>
                {language === "en" ? "Submit" : "บันทึก"}
              </a>
            ) : (
              <a
                className={classes.editorRedeem}
                onClick={(e) => setShowEdit(true)}
              >
                {language === "en" ? "Edit Profile" : "แก้ไขประวัติ"}
              </a>
            )}
          </div>
        </form>
      </div>
      <Footer imageIndex={1} />
    </div>
  );
};

export default MyProfile;
